.navbar{
    background-color: gold;
    height: 50;
    width: 80;

    
}
.container {
    display: flex;
    flex-direction: row;
    height: 90vh;
  }
  .containerfixture {
    display: flex;
    
    
    height: 85vh;
  }

.left-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddd;
  }
  
  .center-col {
    flex: 1;
    background: #aaa;
    overflow-y: scroll;
    width: 40vh;
  }
  
  .right-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
  }